import React from 'react'

import PropTypes from 'prop-types'

import './line.css'

const Line = (props) => {
  return <div className={`line-line ${props.rootClassName} `}></div>
}

Line.defaultProps = {
  rootClassName: '',
}

Line.propTypes = {
  rootClassName: PropTypes.string,
}

export default Line
