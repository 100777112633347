import React from "react";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import "./presale-loading.css";

const PresaleLoading = (props) => {
  return (
    <div className="presale-loading-container1">
      <Helmet>
        <title>OfficeX Token Presale</title>
        <meta
          name="description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. For Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever"
        />
        <meta property="og:title" content="OfficeX Token Presale" />
        <meta
          property="og:description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. Made for Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd80591-3bea-4d98-908e-93babf010021/4929c1c1-f2db-4ea6-895e-739a57049a5f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <h1 className="presale-loading-text1">
        <span>OfficeX</span>
        <br></br>
        <span>Token Presale</span>
        <br></br>
      </h1>
      <a
        href="https://drive.officex.app/presale"
        style={{ color: "blue", textDecoration: "underline" }}
      >
        <h2 className="presale-loading-text6">View Presale</h2>
      </a>
    </div>
  );
};

export default PresaleLoading;
