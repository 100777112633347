import React from "react";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import "./whitepaper-loading.css";

const MediaKitPage = (props) => {
  return (
    <div className="whitepaper-loading-container1">
      <Helmet>
        <title>OfficeX Media Kit</title>
        <meta
          name="description"
          content="OfficeX MediaKit - Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. For Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever"
        />
        <meta property="og:title" content="OfficeX Whitepaper" />
        <meta
          property="og:description"
          content="OfficeX Media Kit - Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. Made for Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd80591-3bea-4d98-908e-93babf010021/4929c1c1-f2db-4ea6-895e-739a57049a5f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <h1 className="whitepaper-loading-text1">
        <span>OfficeX</span>
        <br></br>
        <span>Media Kit</span>
        <br></br>
      </h1>
      <a
        href="https://regal-feta-f2a.notion.site/OfficeX-Media-Kit-147bebffcd78807a83dcd89da24f47cd?pvs=4"
        style={{ color: "blue", textDecoration: "underline" }}
      >
        <h2 className="whitepaper-loading-text6">Open Media Kit</h2>
      </a>
    </div>
  );
};

export default MediaKitPage;
