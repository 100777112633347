import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./style.css";
import OFXPreSeedSale from "./views/ofx-pre-seed-sale";
import Home from "./views/home";
import PresaleLoading from "./views/presale-loading";
import WhitepaperLoading from "./views/whitepaper-loading";
import NotFound from "./views/not-found";
import CommunityPage from "./views/community";
import MediaKitPage from "./views/media-kit";
import BuyPage from "./views/buy";
import AffiliatesPage from "./views/affiliates";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={OFXPreSeedSale} exact path="/preseed" />
        <Route component={Home} exact path="/" />
        <Route component={PresaleLoading} exact path="/presale" />
        <Route component={WhitepaperLoading} exact path="/whitepaper" />
        <Route component={CommunityPage} exact path="/community" />
        <Route component={MediaKitPage} exact path="/mediakit" />
        <Route component={BuyPage} exact path="/buy" />
        <Route component={AffiliatesPage} exact path="/affiliate" />
        <Route component={AffiliatesPage} exact path="/affiliates" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
