import React, { useEffect } from "react";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";
import { message } from "antd";

import "./whitepaper-loading.css";

const BuyPage = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <div
      className="whitepaper-loading-container1"
      style={{ textAlign: "center", padding: "10px" }}
    >
      {contextHolder}
      <Helmet>
        <title>Buy $OFFICEX</title>
        <meta
          name="description"
          content="Join the OfficeX Community. For Freedom Lovers. Private &amp; Secure. 100% Free Forever"
        />
        <meta property="og:title" content="OfficeX Community" />
        <meta
          property="og:description"
          content="Join the OfficeX Community. For Freedom Lovers. Private &amp; Secure. 100% Free Forever."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd80591-3bea-4d98-908e-93babf010021/4929c1c1-f2db-4ea6-895e-739a57049a5f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <h1 className="whitepaper-loading-text1">
        <span>Buy $OFFICEX</span>
        <br></br>
        <br></br>
      </h1>
      <h3>Trading on Uniswap BaseL2</h3>
      <br />
      <span
        onClick={() => {
          navigator.clipboard.writeText(
            "0x48808407d95f691D076C90337d42eE3836656990"
          );
          messageApi.success("Copied to clipboard");
        }}
        style={{ cursor: "pointer" }}
      >
        $OFFICEX (click to copy) 0x48808407d95f691D076C90337d42eE3836656990
      </span>
      <a
        href="https://app.uniswap.org/swap?inputCurrency=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913&outputCurrency=0x48808407d95f691D076C90337d42eE3836656990"
        style={{ color: "blue", textDecoration: "underline" }}
      >
        <h2 className="whitepaper-loading-text6">👉 Trade on Uniswap</h2>
      </a>
    </div>
  );
};

export default BuyPage;
