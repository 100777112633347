import React from "react";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import "./whitepaper-loading.css";

const CommunityPage = (props) => {
  return (
    <div className="whitepaper-loading-container1">
      <Helmet>
        <title>OfficeX Community</title>
        <meta
          name="description"
          content="Join the OfficeX Community. For Freedom Lovers. Private &amp; Secure. 100% Free Forever"
        />
        <meta property="og:title" content="OfficeX Community" />
        <meta
          property="og:description"
          content="Join the OfficeX Community. For Freedom Lovers. Private &amp; Secure. 100% Free Forever."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd80591-3bea-4d98-908e-93babf010021/4929c1c1-f2db-4ea6-895e-739a57049a5f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <h1 className="whitepaper-loading-text1">
        <span>OfficeX</span>
        <br></br>
        <span>Community</span>
        <br></br>
      </h1>
      <a
        href="https://tally.so/r/wMrVbY"
        style={{ color: "blue", textDecoration: "underline" }}
      >
        <h2 className="whitepaper-loading-text6">👉 Proceed to Signup</h2>
      </a>
    </div>
  );
};

export default CommunityPage;
